@font-face {
font-family: '__articulat_82383d';
src: url(/_next/static/media/ce7a1d98daabcd5e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__articulat_82383d';
src: url(/_next/static/media/fa5f9f81003371e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__articulat_82383d';
src: url(/_next/static/media/9baf16f922c8bd99-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__articulat_82383d';
src: url(/_next/static/media/a5275959c5794adf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}@font-face {font-family: '__articulat_Fallback_82383d';src: local("Arial");ascent-override: 109.64%;descent-override: 40.18%;line-gap-override: 17.86%;size-adjust: 100.78%
}.__className_82383d {font-family: '__articulat_82383d', '__articulat_Fallback_82383d'
}.__variable_82383d {--font-articulat: '__articulat_82383d', '__articulat_Fallback_82383d'
}

/* music */
@font-face {
  font-family: '__Noto_Music_a6c0e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0d417f27097c5d91-s.p.woff2) format('woff2');
  unicode-range: U+25CC, U+2669-266F, U+1D000-1D0F5, U+1D100-1D126, U+1D129-1D1EA, U+1D200-1D245;
}
/* latin-ext */
@font-face {
  font-family: '__Noto_Music_a6c0e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/228dbc08dfdada50-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Noto_Music_a6c0e9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/12e77d4c294e54ea-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Noto_Music_Fallback_a6c0e9';src: local("Arial");ascent-override: 130.64%;descent-override: 37.43%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_a6c0e9 {font-family: '__Noto_Music_a6c0e9', '__Noto_Music_Fallback_a6c0e9';font-weight: 400;font-style: normal
}.__variable_a6c0e9 {--font-noto: '__Noto_Music_a6c0e9', '__Noto_Music_Fallback_a6c0e9'
}

